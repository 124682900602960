import React, { useEffect, useState } from "react";
import { Homeheader } from "./homeHeader";
import { Footer } from "./footer";
import axios from "axios";
import { SendMess } from "./messSend";
import { ExosSubmit } from "./ExoSubmit";
import { useNavigate } from "react-router-dom";

export const MyEspace = () => {
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [email, setEmail] = useState("");
  const [numero, setNumero] = useState("");
  const [update, setUpdate] = useState(false);
  const [but, setBut] = useState(false);
  const [id, setId] = useState(null);
  const [dis, setDis] = useState(false);
  const [disex, setDisex] = useState(false);
  // updates informations
  const [updatenom, setUpdatenom] = useState("");
  const [updateprenom, setUpdateprenom] = useState("");
  const [updatemail, setUpdatemail] = useState("");
  const [updatenum, setUpdatenum] = useState("");
  const [message, setMessage] = useState("");
  const [fichier, setFichier] = useState(false);
  const [axio, setAxio] = useState(false);
  const [files, setFiles] = useState([]);
  const [exo, setExo] = useState(false);
  const [post, setPost] = useState(true);
  const [numerr, setNumerr] = useState(false);
  const [mailerr, setMailerr] = useState(false);
  const [nomerr, setNomerr] = useState(false);
  const [prenomerr, setPrenomerr] = useState(false);
  const [messdis, setMessdis] = useState(false);
  const [validate, setValidate] = useState(false);
  const [link, setLink] = useState(false);
  const navigate = useNavigate()
  const [article, setArticle] = useState("");

  function nav(link){
    navigate(`${link}`)
  }

  function Test() {
    const CharNum = /^[0-9]+$/;

    if (updatenum !== "" && updatenum !== null) {
      const testNum = CharNum.test(updatenum);
      setNumerr(!(testNum && updatenum.length >= 9 && updatenum.length <= 14));
    }
    const CharMail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (updatemail !== "" && updatemail !== null) {
      const testMail = CharMail.test(updatemail);
      setMailerr(!testMail);
    }
    if (updatenom == "") {
      setNomerr(true);
    } else {
      setNomerr(false);
    }

    if (updateprenom == "") {
      setPrenomerr(true);
    } else {
      setPrenomerr(false);
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (post) {
      let data, url;

      if (axio) {
        url = `${process.env.REACT_APP_API_URL_BACK}/submit/mess/send`;
        data = {
          Message: message,
          Nom: nom,
          Prenom: prenom,
          Numero: numero,
          Email: email,
          id: id,
        };
      } else {
        url = `${process.env.REACT_APP_API_URL_BACK}/submit/update/infos`;
        data = {
          updateNom: updatenom,
          updatePrenom: updateprenom,
          updateMail: updatemail,
          updateNum: updatenum,
          id: id,
        };
      }
      // Envoi des données via axios

      axios
        .post(url, data, { withCredentials: true })
        .then((res) => {
          if (axio) {
            setTimeout(() => {
              setDis(false);
              alert("Message envoyé avec succès");
            }, 2000);
          } else {
            setUpdate(false);
            localStorage.setItem("Nom", updatenom);
            localStorage.setItem("Prenom", updateprenom);
            localStorage.setItem("Email", updatemail);
            localStorage.setItem("Number", updatenum);
            alert("Modifications enregestrées !");
            nav('/espace');
            setBut(false);
            setAxio(false);
            setExo(false);
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.Message.includes("messages ")
          ) {
            setArticle(
              "Vous ne pouvez pas envoyé plus de 3 messages pour le moment"
            );
          }
          alert("Une erreur est survenue");
        });
    }
  };
  const fic = new FormData();
  async function PostExo() {
    if (files && files.length > 0) {
      for (var i = 0; i < files.length; i++) {
        fic.append("files", files[i]);
      }
    }

    fic.append("Nom", nom);
    fic.append("Prenom", prenom);
    fic.append("Numero", numero);
    fic.append("Email", email);
    fic.append("id", id);

    await axios
      .post(
        `${process.env.REACT_APP_API_URL_BACK}/submit/exo/post`,
        fic,
        { withCredentials: true },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        setTimeout(() => {
          setDisex(false);
          alert("Exo soumit avec succès");
        }, 2000);
        setFiles(null);
      })
      .catch((err) => {
        alert("Une erreur est survenue !");
      });
  }

  useEffect(() => {
    const Nom = localStorage.getItem("Nom");
    const Prenom = localStorage.getItem("Prenom");
    const Email = localStorage.getItem("Email");
    const Numero = localStorage.getItem("Number");
    const id = localStorage.getItem("id");

    setNom(Nom);
    setId(id);
    setPrenom(Prenom);
    setEmail(Email);
    setNumero(Numero);
  });

  useEffect(() => {
    axios({
      method: "get",
      withCredentials: true,
      url: `${process.env.REACT_APP_API_URL_BACK}/submit/admin/order/check/user`,
    })
      .then((res) => {
        if (res.data.Message === "success") {
          setLink(true);
        }
      })
      .catch((err) => {
        // alert("Une erreur est survenue !");
      });
  }, []);

  useEffect(() => {
    if (update && !mailerr && !numerr && !prenomerr && !nomerr) {
      setValidate(true);
    } else {
      setValidate(false);
      //setPost(true);
    }

    if (message && message !== "" && message.length >= 30) {
      setMessdis(true);
      setPost(true);
    } else {
      setMessdis(false);
    }
    if (!message || (message == "" && update)) {
      setPost(true);
    }

    //console.log(post,{mailerr:mailerr , numerr:numerr , prenomerr:prenomerr , nomerr:nomerr})
    Test();

    if (files && files.length > 0) {
      setFichier(true);
    } else {
      setFichier(false);
    }
  });

  return (
    <>
      <SendMess
        dis={dis}
        res={
          messdis && (
            <button class="close-btn" onClick={handleSubmit}>
              Envoyez
            </button>
          )
        }
        change={
          <>
            <textarea
              id="tareaText"
              required
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Votre message ici"
            ></textarea>
          </>
        }
        cancel={<button onClick={() => setDis(false)}>Ignorer</button>}
        err={article}
      />
      <ExosSubmit
        dis={disex}
        res={
          <>
            fichier && (
            <button
              class="close-btn"
              onClick={() => {
                setExo(true);
                PostExo();
              }}
            >
              Envoyez
            </button>
            )<button onClick={() => setDisex(false)}>Annuler</button>
          </>
        }
        change={
          <input
            className="file-input"
            id="fileUploader"
            required
            type="file"
            name="files"
            onChange={(e) => setFiles(e.target.files)}
            multiple
            maxLength="5"
          />
        }
      />
      <Homeheader props={false} />
      <div className="header-icons">
        <h2 id="welcome-message">
          <img src="../images/bye.png" alt="Bye icon" /> Bienvenue {prenom}{" "}
        </h2>{" "}
        <div>
          {" "}
          <img src="../images/message.png" alt="Message icon" />
          <span></span>{" "}
          <img src="../images/notif.png" alt="Notification icon" />
        </div>{" "}
      </div>
      <div className="wrapper">
        <div className="personal-info-section" id="personal-info-section">
          <div id="pren">
            {!update && (
              <form id="personal-info-form">
                <h1>Mes informations personnelles</h1>
                <img src="../images/prof.png" alt="Profile pic" />
                <label>Prenom</label>
                <br />
                <input
                  type="text"
                  value={prenom}
                  id="prenom"
                  name="prenom"
                  required
                />
                <br />
                <label>Nom</label>
                <br />
                <input type="text" value={nom} id="nom" name="nom" required />
                <br />
                <label>Numero</label>
                <br />
                <input
                  type="text"
                  value={numero}
                  id="numero"
                  name="numero"
                  required
                />{" "}
                <br />
                <label>Adresse mail</label>
                <br />
                <input
                  type="email"
                  value={email}
                  id="email"
                  name="email"
                  required
                />{" "}
                <br />
                <input
                  type="submit"
                  id="submit-btn"
                  value="Modifiez mes infos"
                  onClick={() => {
                    setUpdate(true);
                    setBut(true);
                    setAxio(false);
                    setExo(false);
                  }}
                />
              </form>
            )}
            {update && (
              <form id="personal-info-form" onSubmit={handleSubmit}>
                <h1>Mes informations personnelles</h1>
                <img src="../images/prof.png" alt="Profile pic" />
                <label>Prenom</label>
                <br />
                <input
                  type="text"
                  placeholder="Entrez votre prenom"
                  id="prenom"
                  name="prenom"
                  onChange={(e) => setUpdateprenom(e.target.value)}
                  required
                />
                <br />
                {prenomerr && (
                  <>
                    <p className="error">Le prenom est obligatoire</p>
                    <br />
                  </>
                )}
                <label>Nom</label>
                <br />
                <input
                  type="text"
                  id="nom"
                  name="nom"
                  placeholder="Entrez votre nom"
                  required
                  onChange={(e) => setUpdatenom(e.target.value)}
                />
                <br />
                {nomerr && (
                  <>
                    <p className="error">Le nom est obligatoire</p>
                    <br />
                  </>
                )}
                <label>Numero</label>
                <br />
                <input
                  type="text"
                  id="numero"
                  name="numero"
                  placeholder="Entrez votre numéro"
                  required
                  onChange={(e) => setUpdatenum(e.target.value)}
                />{" "}
                <br />
                {numerr && (
                  <>
                    <p className="error">Y'a une erreur avec le numéro</p>
                    <br />
                  </>
                )}
                <label>Adresse mail</label>
                <br />
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Entrez votre adresse mail"
                  required
                  onChange={(e) => setUpdatemail(e.target.value)}
                />{" "}
                <br />
                {mailerr && (
                  <>
                    <p className="error">Y'a une erreur sur le mail saisi</p>
                    <br />
                  </>
                )}
                <div className="form-btn-group">
                  {validate && (
                    <input
                      type="submit"
                      id="submit-btn"
                      disabled={!validate}
                      value="Valider les modifications"
                      onClick={handleSubmit}
                    />
                  )}
                  {but && (
                    <p
                      id="cancel-btn"
                      style={{
                        width: "66%",
                        textAlign: "center",
                        marginTop: "3vh",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setUpdate(false);
                        setBut(false);
                      }}
                    >
                      Annuler les modifications
                    </p>
                  )}
                </div>
                <br />
              </form>
            )}
          </div>
          <br />
        </div>
        <div className="training-section" id="personal-info-section">
          <div className="button-group">
            <button
              className="action-btn"
              onClick={() => {
                setDis(true);
                setExo(false);
                setAxio(true);
              }}
            >
              <img src="../images/sended.png" alt="Send message" /> Envoyer un
              message
            </button>
            {link ? (
              <button
                className="action-btn"
                onClick={() => {
                  setDisex(true);
                  setUpdate(false);
                }}
              >
                <img
                  src="../images/exo.png"
                  alt="Submit exercise"
                  onClick={() => {
                    setDis(false);
                    setUpdate(false);
                  }}
                />{" "}
                Soumettre un exercice
              </button>
            ) : (
              <button className="action-btn">
                <img src="../images/exo.png" alt="Submit exercise" /> Acheter
                une formation d'abord pour pouvoir soumettre un exercice
              </button>
            )}
            <button
              className="action-btn"
              onClick={() => {
                setUpdate(true);
                setBut(true);
                setPost(true);
                document.querySelector("#pren").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }}
            >
              <img src="../images/edit.png" alt="Edit info" /> Modifier vos
              informations
            </button>
          </div>
          <h1>Mes Formations</h1>
          <div id="training-courses">
            <div className="course-item-group">
              <div className="course-item">
                <img src="../images/node-js.png" alt="Node.js course" />
                <a
                  href='' 
                  onClick={(e)=>{
                    e.preventDefault()
                    {link ? nav('/mys') : nav('/mysql_node')}
                  }}
                 >
                  {link ? "Suivre ma formation" : "Formation MYSQL nodeJs"}
                </a>
                {link
                  ? "Dèja achteté !"
                  : "Acheter la formation en cliquant sur son lien"}
              </div>
              <div className="course-item">
                <img src="../images/code3.png" alt="Full Stack course" />
                <a href='' onClick={(e)=>{
                  e.preventDefault()
                  nav('/full')
                }}>
                  Formation complète Full stack
                </a>
                Acheter la formation en cliquant sur son lien
              </div>
            </div>
            <div className="course-item-group">
              <div className="course-item">
                <img src="../images/pro.png" alt="React JS course" />
                <a href='' onClick={(e)=>{
                  e.preventDefault()
                  nav('/html_react')
                }}>
                  Formation HTML CSS REACT JS
                </a>
                Acheter la formation en cliquant sur son lien
              </div>
              <div className="course-item">
                <img src="../images/javascript.png" alt="JavaScript course" />
                <a href='' onClick={(e)=>{
                  e.preventDefault()
                  nav('/mysql_js')
                }}>
                  Formation MYSQL nodeJs + javaScript
                </a>
                Acheter la formation en cliquant sur son lien
              </div>
            </div>
          </div>
          <div className="links-section">
          <a href='' onClick={(e)=>{
                  e.preventDefault()
                  nav('/coming')
                }}>
              Accéder à la communauté ?
            </a>
            <a href='' onClick={(e)=>{
                  e.preventDefault()
                  nav('/formations')
                }}>
              Accéder aux formations ?
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
