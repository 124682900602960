import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert } from "./alert";
export const Unautthorized = () => {
  const [dis, setDis] = useState(true);
  const navigate = useNavigate()

  function nav(link) {
    navigate(`${link}`);
  }
  return (
    <>
      <Alert
        message={"Connectez-vous pour accéder à cette page"}
        titre="Non authorisé"
        sous={""}
        dis={dis}
        button={
          <button
            class="close-btn"
            onClick={(e) => {
              setDis(false);
              e.preventDefault()
              nav('/regester/signin')
              
            }}
          >
            Me connecter
          </button>
        }
        img={false}
      />
    </>
  );
};
