import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { filterComment } from "./Options/filterComment";
import moment from "moment";
const SingleVid = () => {
  const content = useSelector((state) => state.reducer.media);
  const feed = useSelector((state) => state.reducer.comment);

  const [commenteur, setCommenteur] = useState([]);
  const [comment, setComment] = useState("");
  const [name, setName] = useState("");
  const [prenom, setPrenom] = useState("");
  const [nom, setNom] = useState("");
  const [dis, setDis] = useState(true);
  const [commentdata, setCommentdata] = useState(null);
  const item =  localStorage.getItem("name");
  const posterId = localStorage.getItem("id");
  const langage =  localStorage.getItem("languages");
  

  useEffect(() => {
    setPrenom(localStorage.getItem("Prenom"));
    setNom(localStorage.getItem("Nom"));
    if(content && content[0]){
      setName(content[0].name);
    }
  },[]);

  let cours;
  let id;
  let course;
   if (
    content &&
    content[0] &&
    content[0].cours 
  ) {
    cours = content[0].cours;
    course = cours.filter((cour) => cour.names == item);

    if(course[0] && course[0].id)
    {
    id = course[0].id;
    }
  } else {
    return (
      <>
        <div>
          <p className="error">
            Retournez à la page précédente et recliquez sur le button voir la
            vidéo...
          </p>
        </div>
      </>
    );
  }
  useEffect(()=>{
    const dta =  filterComment(feed,id,langage)
    setCommentdata(dta)
  },[])
  const handleComment = async () => {
    if (content[0].name && id && posterId && prenom && nom) {
      if (comment.length === 0 || comment.length === null || comment == "") {
        alert("Commentaire vide !");
      } else {
        axios({
          method: "post",
          withCredentials: true,
          url: `${process.env.REACT_APP_API_URL_BACK}/submit/comment`,
          data: {
            comment: comment,
            name: name,
            id: id,
            item: item,
            posterId: posterId,
            numero: "1963-24",
            prenom: prenom,
            nom: nom,
          },
        })
          .then((res) => {
            setCommenteur([...commenteur, res.data.comment]);
            setDis(true);
          })
          .catch((err) => {
            alert('Une erreur est survenue !')
          });
      }
    }
  };

  return (
    <>
      {course &&
        cours.length > 0 &&
        course.map((datas) => {
          return (
            <>
              <div className="main-bloc">
                <div className="contentOne">
                  <video
                    id="maVideo"
                    controls
                    controlsList="nodownload"
                    className="fullscreen-video"
                  >
                    <source
                      className="vid"
                      type="video/mp4"
                      src={`${process.env.REACT_APP_API_URL_BACK_NO}/videos/${datas.names}`}
                    />
                  </video>
                  <div className="title">
                    <h1>{datas.titres}</h1>
                    <p>{datas.soustitres}</p>
                  </div>
                </div>
                <div className="contentTwo">
                  <p>Description</p>
                  <article>{datas.descriptions}</article>
                  <div className="comments">
                    <p>Commentaires</p>
                    {commenteur.map((commenteurs) => {
                      return (
                        <div className="class-comment">
                          <>
                            {dis && (
                              <>
                                <div className="profil">
                                  <p id="prenom">
                                    {prenom} {""} {nom}{" "}
                                  </p>
                                  <p>.maintenant</p>
                                </div>
                                <p></p>
                                <p id="comment">{commenteurs}</p>
                              </>
                            )}
                          </>
                        </div>
                      );
                    })}
                    {commentdata && commentdata.length>0 && commentdata.map((feeds) => {
                      return (
                        <div className="class-comment">
                          <>
                            {dis && (
                              <>
                                <div className="profil">
                                  <p id="prenom">
                                    {feeds.prenom} {""} {feeds.nom}{" "}
                                  </p>
                                  <p>{moment(feeds.createdAt).format('DD/MM/YY   HH: mm: ss')}</p>
                                </div>
                                <p></p>
                                <p id="comment">{feeds.comments}</p>
                              </>
                            )}
                          </>
                        </div>
                      );
                    })}
                    <div>
                      <label>Laissez un commentaires</label>
                      <textarea
                        placeholder="Laissez un commentaire"
                        required
                        onChange={(e) => setComment(e.target.value)}
                      ></textarea>
                      <input type="submit" onClick={handleComment} />
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
    </>
  );
};

export default SingleVid;
