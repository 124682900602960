import React from "react";


export const Coming = ()=>{
    return(
        <>
            <div className="coming">
                <h2>Cette page sera bientôt disponible <br/> Restez connecté </h2>
                <img src="../images/coming.png.png"/>
            </div>
        </>
    )
}