import React, { useEffect, useState } from "react";
import axios from "axios";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Formations } from "../components/formations";
import { Home } from "../components/home";
import { Mysql_node } from "../components/mysql_node";
import { Html_react } from "../components/react_html";
import { Mysql_js } from "../components/mysql_js";
import { Full } from "../components/full";
import { InscriptionForm } from "../components/inscriptionForm";
import { Login } from "../components/login";
import { MyEspace } from "../components/MyEspace";
import { Unautthorized } from "../components/auth";
import { SendCookie } from "../components/actions/action";
import { useDispatch } from "react-redux";
import { Coming } from "../components/coming";
import { MysqlForm } from "../components/mysqlFormation";
import { InscriptionFormAdmin } from "../components/adminInsciptio";
import { AdminLogin } from "../components/adminLogin";
import { AdminDash } from "../components/adminDash";
import { FreeCourses } from "../components/freecourses";
import { ForgetPass } from "../components/passForget";
import SingleVid from "../components/singleVid";
import {Communauty} from "../components/communauty";

export const Main = () => {
  const [permission, setPermission] = useState(false);
  const [admin, setAdmin] = useState(false);
  const id = localStorage.getItem("id");
  const email = localStorage.getItem("Email");
  const dispatch = useDispatch();
  useEffect(() => {
    const CheckAuth = async () => {
      if (id && email) {
        await axios({
          method: "get",
          withCredentials: true,
          url: `${process.env.REACT_APP_API_URL_BACK}/check/auth/${email}/${id}`,
        })
          .then((res) => {
            if (res.data.Message === "success" || res.status === 200) {
              setPermission(true);
              dispatch(SendCookie({ Authorization: "True" }));
            } else {
              setPermission(false);
              dispatch(SendCookie({ Authorization: "False" }));
            }
          })
          .catch((error) => {
            setPermission(false);
          });
      } else {
        // window.location.href=`${process.env.REACT_APP_API_URL}/regester/signin`
      }
    };
    CheckAuth();

    const CheckAuthAdmin = async () => {
        await axios({
          method: "get",
          withCredentials: true,
          url: `${process.env.REACT_APP_API_URL_BACK}/submit/check/admin`,
        })
          .then((res) => {
            if (res.data.Message === "success" || res.status === 200) {
              setAdmin(true);
            } else {
              setAdmin(false);
            }
          })
          .catch((error) => {
            setAdmin(false);
          });
    };
    CheckAuth();
    CheckAuthAdmin();
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/formations" element={<Formations />} />
        <Route path="*" element={<Home />} />
        <Route path="/mysql_node" element={<Mysql_node />} />
        <Route path="/html_react" element={<Html_react />} />
        <Route path="/mysql_js" element={<Mysql_js />} />
        <Route path="/regester/login" element={<InscriptionForm />} />
        <Route path="/regester/signin" element={<Login />} /
        >
        <Route path="/full" element={<Full />} />

        <Route
          path="/espace" element={permission ? <MyEspace /> : <Unautthorized />}
        />
        <Route
          path="/singlevid"
          element={permission ? <SingleVid /> : <Unautthorized />}
        />
          {/*<Route path='/communauty'  element={<Communauty/>} />*/}
          <Route
          path="/freecourses"
          element={<FreeCourses /> }
        />
        <Route path="/coming" element={<Coming />} />
        <Route path="/regester/forgetpass" element={<ForgetPass />} />
        <Route path="/mys" element={<MysqlForm />} />
        <Route
          path="/regester/login/admin/create/account"
          element={<InscriptionFormAdmin />}
        />
        <Route
          path="/regester/login/admin/login/account"
          element={<AdminLogin />}
        />
        <Route
          path="/admin/dashboard/infos"
          element={admin ? <AdminDash /> : <Unautthorized />}
        />
      </Routes>
    </Router>
  );
};
