import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { Alert } from "./alert";
import { useNavigate } from "react-router-dom";

export const ForgetPass = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [pass, setPass] = useState("");
  const [generalerr, setGeneralerr] = useState("");
  const [dis, setDis] = useState(false);
  const [otp, setOtp] = useState('');
  const [code, setCode] = useState(false);
  const [pat,setPat] = useState(false)
  const [passerr,setPasserr] = useState(false)
  const [newpass,setNewpass] = useState(false)
  const [iscode,setIscode] = useState(false)
  const navigate = useNavigate()

  function nav(link){
    navigate(`${link}`)
  }
  const SubOtp = ()=>{


    if(otp !== pass){
        setPasserr(true)
    }else{
        setPasserr(false)
        setNewpass(true)
        setCode(false)
    }
  }

  const handleSubmit = (e) => {
    
    if(!code && password.length > 0){
      setIscode(true)
    }
    
    e.preventDefault();
    setPassword('')
    if(!code){
        axios
        .post(
          `${process.env.REACT_APP_API_URL_BACK}/submit/forget/pass`,
          {
            email: email,
            password:password,
            id:localStorage.getItem('id')
          },
          { withCredentials: true }
        )

        .then((res) => {
          setDis(true);
          setOtp(res.data.OTP);
          if(password == '' || password == null){
            setCode(true);
          }else{
            setIscode(false)
            setCode(false)
            if(confirm('Mot de passe changé avec succès connectez-vous maintenant !')){
                nav('/regester/signin`')
            }
          }
        })
        .catch((err) => {
          if(err.response && err.response.status === 500){
            setGeneralerr(
              "Le mail que vous avez tenté d'envoyer est différent du mail de votre compte,verifiez votre mail et réssayez"
            );            
          }
          else{
            setGeneralerr(
              "Verifiez votre adresse mail ou ressayez !"
            );
        }
        });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit} id="form-contact">
        <div className="form-contact-div" id="form-contact-div">
          <div className="form-contact-div-first" id="form-contact-div-p">
            <h3 id="h1">Changez de mot de passe !</h3>
            <br />
            {iscode && (
              <p>Chargement,veuillez patienté...</p>
            )}
            {!code && (
              <>
              <br/>
                <label htmlFor="email">Adresse mail</label>
                <br />
                <input
                  type="email"
                  required
                  name="email"
                  placeholder="Votre adresse email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </>
            )}
            {!code && newpass && (
              <>
              <br/>
                <label htmlFor="email">Entrez un nouveau mot de passe</label>
                <br />
                <input
                  type="password"
                  required
                  name="password"
                  placeholder="Votre nouveau mot de passe"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </>
            )}
            <br />
            {code && (
              <>
              <label style={{fontSize:"17px"}}>Nb: le code peut prendre jusqu'à`5 minutes pour arriver</label><br/>
                <label htmlFor="password">
                  Entrez le code que vous avez reçu par mail*
                </label>
                <br />
                <input
                  type="tel"
                  required
                  placeholder="Entrez le code"
                  name="code"
                  onChange={(e) => {
                    setPass(e.target.value);
                  }}
                />
                {passerr && <p className="error">Verifiez le code saisi, incorrect</p>}
                <button onClick={SubOtp}>Valider</button>
                <p style={{cursor:'pointer'}} onClick={()=>{
                   setPat(true)
                   setPassword('')
                   setTimeout(() => {
                    setCode(false)
                    setPat(false)
                   }, 5000);
                }} disabled={true}>Demander un nouveau code </p>
              </>
            )}
            <br />
            <p style={{ color: "red" }} id="passerr">
              {generalerr}
            </p>
            {pat && <label>Veuillez patienter...</label>}
            {!code && (
              <input type="submit" id="submit" value={!code ? 'Valider' : 'Demander un code'} />
            )}
          </div>
        </div>
      </form>
    </>
  );
};
