import React from "react";
import { SignleForm } from "./singleFormModel";

export const Html_react = () => {
  const nom = "Formation HTML CSS REACT JS front-end";
  const prix = "8.500 Fr";
  const icon = "../images/img.svg";
  const description = `Maîtrise les bases du développement web et apprends à créer des
            interfaces utilisateur modernes avec HTML, CSS et React.js ! 
            Ce que tu apprendras : 
            Structurer des pages web avec HTML 
            Styliser et rendre tes sites interactifs avec CSS 
            Créer des interfaces dynamiques et performantes avec React.js 
            Formation en ligne flexible, avec projets pratiques`;

  const img = "../images/pro.png";
  const imag = "../images/not.png";
  const aper = null;

  return (
    <>
      <SignleForm
        nom={nom}
        prix={prix}
        programme={
          <ul>
            <li>
              <img src={icon} alt="pic-video" /> Introduction à HTML : Structure de base
            </li>
            <li>
              <img src={icon} alt="pic-video" /> Élément HTML : Balises, attributs et sémantique
            </li>
            <li>
              <img src={icon} alt="pic-video" /> Introduction à CSS : Sélecteurs et propriétés
            </li>
            <li>
              <img src={icon} alt="pic-video" /> Mise en page avec CSS : Flexbox et Grid
            </li>
            <li>
              <img src={icon} alt="pic-video" /> Responsivité : Media Queries et design adaptatif
            </li>
            <li>
              <img src={icon} alt="pic-video" /> Introduction à JavaScript : Interactivité de base
            </li>
            <li>
              <img src={icon} alt="pic-video" /> Concepts fondamentaux de React.js
            </li>
            <li>
              <img src={icon} alt="pic-video" /> Création de composants fonctionnels et classes
            </li>
            <li>
              <img src={icon} alt="pic-video" /> Gestion de l'état avec useState et useEffect
            </li>
            <li>
              <img src={icon} alt="pic-video" /> Props et communication entre composants
            </li>
            <li>
              <img src={icon} alt="pic-video" /> Gestion des formulaires en React
            </li>
            <li>
              <img src={icon} alt="pic-video" /> Introduction à React Router : Navigation dans l'application
            </li>
            <li>
              <img src={icon} alt="pic-video" /> Appel d'API et gestion des données
            </li>
            <li>
              <img src={icon} alt="pic-video" /> Déploiement d'applications React
            </li>
            <li>
              <img src={icon} alt="pic-video" /> Projet final : Création d'une application complète
            </li>
          </ul>
        }
        description={description}
        img={img}
        imag={imag}
        aper={aper}
      />
    </>
  );
};
