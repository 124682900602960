import React from "react";

export const About = () => {
  return (
    <>
      <h1 id="h2">A Propos</h1>
      <div className="glob">
        <div className="class1">
          <div className="child">
            <h1><a>01</a>Compétences</h1>
            <p>
              <span>Langages et technologies backend</span> : Node.js, MySQL,
              Express.js, API <br />
              REST, JWT, OAuth 2.0.<span> Sécurité des données</span> : Cryptage
              AES, Hachage
              <br />
              SHA-256, gestion des clés, protection des données sensibles.
              <br />
              Langages frontend : React.js, JavaScript (ES6+), HTML5, CSS3.
              <br />
              <span>Gestion des bases de données</span> : Modélisation des bases
              de données
              <br />
              relationnelles, optimisation des requêtes, transactions ACID.
              <br />
              Outils : Git, Docker, cPanel, Git Bash, Webpack.
              <br />
            </p>
          </div>
          <div className="child">
            <h1><a>02</a>Disponibilité</h1>
            <p>
              <span>
                {" "}
                Je suis disponible pour des missions freelance à distance, des{" "}
                <br />
                projets de développement backend, ainsi que des collaborations
                <br />
                dans les domaines du développement web
              </span>{" "}
              (Node.js, React.js, MySQL)
              <br />
              et de la gestion de bases de données sécurisées. Mon expertise
              <br />
              inclut la création de systèmes robustes et évolutifs,
              <br />
              l’optimisation des performances, et la sécurisation des données.
              <br />
              Ouvert à toute opportunité de collaboration professionnelle à
              <br />
              distance. N’hésitez pas à me contacter pour discuter de vos
              <br />
              besoins ou de vos projets.
              <br />
            </p>
          </div>
        </div>
        <div className="class1">
          <div className="child">
            <h1> <a>03</a> Profil</h1>
            <p>
              <span>Développeur backend spécialisé en bases de données</span>
              <br />
              <span>MySQL et sécurisation des données.</span> J'excelle dans le
              <br />
              développement d'applications web sécurisées, avec des pratiques
              <br />
              comme : <span>hachage des mots de passe</span> avec bcrypt,{" "}
              <span>cryptage</span>
              <br />
              des données sensibles, et <span>
                limitation des requêtes
              </span>{" "}
              pour prévenir les abus.
              <br />
              Je protège contre les attaques XSS et sécurise les
              <br />
              requêtes SQL pour éviter les injections, en assurant l'intégrité
              des données.
              <br /><hr/><br/>
              Utilisation de <span>cookies sécurisés</span> pour gérer les
              sessions, mise en place
              <br />
              de <span>CORS</span> pour contrôler l'accès, et{" "}
              <span>module crypto</span> pour le
              <br />
              chiffrement. Je me défends contre les attaques CSRF avec{" "}
              <span>csurf</span> dans Node.js,
              <br />
              et j'utilise des <span>variables d'environnement</span> pour les
              configurations sensibles.
              <br />
              Passionné par la création de systèmes robustes, je suis engagé
              dans la
              <br />
              sécurité des applications web. Ouvert aux missions freelance et
              <br />
              collaborations à distance.
            </p>
          </div>
          <div className="child">
            <h1> <a>04</a> Expériences</h1>
            <p>
              <span>
                {" "}
                2024 - Présent Développeur backend - Africaecomm Développement{" "}
              </span>{" "}
              <br />
              d'API sécurisées et performantes pour la gestion des transactions
              <br />
              et des données critiques. Conception et implémentation de bases de
              <br />
              données MySQL sécurisées avec des mécanismes de cryptage et de
              <br />
              hachage. Mise en place de solutions pour assurer la haute
              <br />
              disponibilité des données et optimiser les requêtes SQL.
              <br />
              Intégration d'applications frontend React.js avec des services
              <br />
              backend via Node.js. <br /> <hr/>
              <br />
              <span>2020 - 2024 Développeur web full stack</span> - Freelance
              Conception de
              <br />
              systèmes de gestion des données sécurisés avec des API RESTful
              <br />
              basées sur Node.js et MySQL. Développement d'interfaces
              <br />
              utilisateur dynamiques avec React.js et intégration d'appels API
              <br />
              pour des performances optimisées. Gestion des authentifications
              <br />
              sécurisées avec JWT (JSON Web Tokens) et OAuth 2.0.
              <br />
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
