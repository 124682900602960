import React from "react";

export const Footer = () => {
  return (
    <>
      <div className="footer">
        <img src="../images/icon.png"/>
        <div>
        <p>
            Nous sommes à l'avant-garde de l'innovation numérique au Sénégal.💻
            <br/>
            All right reeserved
        </p>
        </div>
      </div>
    </>
  );
};
