import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { Alert } from "./alert";
import { useNavigate } from "react-router-dom";

export const Order = () => {
  const allow = useSelector((state) => state.reducer.cookie);
  const [dis, setDis] = useState(false);
  const [anim, setAnim] = useState([]);
  const [data, setData] = useState("");
  const [choice, setChoice] = useState("full");
  const [then, setThen] = useState(false);
  const [start, setStart] = useState(false);
  const [mail, setMail] = useState("");
  const [prenom, setPrenom] = useState("");
  const [nom, setNom] = useState("");
  const [numero, setNumero] = useState("");
  const [disp, setDisp] = useState(false);
  const [h1,setH1] = useState('Erreur')
  const [h2,setH2] = useState('Une erreur est survenue ')
  const [article,setArticle] = useState('Actualisez la page et ressayez dans un instant !')
  const navigate = useNavigate()

  function nav(link){
    navigate(`${link}`)
  }
  const handleSubmit = () => {
    setStart(true);
    axios({
      method: "post",
      data: {
        choice: choice,
        email: mail,
        prenom: prenom,
        nom: nom,
        numero: numero,
      },
      url: `${process.env.REACT_APP_API_URL_BACK}/order`,
      withCredentials: true,
    })
      .then(() => {
        setStart(false)
        setTimeout(() => {
          setThen(true);
          setAnim([])
        }, 1500);
        setAnim(false)
      })
      .catch((err) => {
        setDisp(true);

        if(err.response.status==500){
          setH1('........')
          setArticle('Vous avez dèja envoyez une demande de cette formation !')
        }
        if (
          err.response &&
          err.response.data.err &&
          err.response.data.err.name &&
          err.response.data.err.name === "TokenExpiredError"
        ) {
          setArticle('Session de connexion expirée reconnectez-vous !')
          nav('/regester/signin');
        }

      });
  };
  let j = { number: 0 };
  const array = [];
  useEffect(
    () => {
      if (start && then==false) {
        for (let i = 0; i <= 7; i++) {
          setTimeout(() => {
            if (i == 5) {
              j.number = 1;
            }
            array.push(i);
            setAnim([...array]);
          }, 1000 * i);
        }
      }

      return () => {
        setStart(false);
        setAnim([]);
      };
    },
    [start],
    [j.number],[then]
  );
  useEffect(() => {
    const nom = localStorage.getItem("Nom");
    const prenom = localStorage.getItem("Prenom");
    const mail = localStorage.getItem("Email");
    const numero = localStorage.getItem("Number");

    setNom(nom);
    setPrenom(prenom);
    setMail(mail);
    setNumero(numero);

    setData(allow);
    if (data.Authorization == "True") {
      setDis(true);
    } else {
      setDis(false);
    }
  });
  return (
    <div id="or">
      <>
        <Alert
          message={article}
          titre={h1}
          sous={h2}
          dis={disp}
          button={
            <button
              class="close-btn"
              onClick={() => {
                setDisp(false);
              }}
            >
              Fermer
            </button>
          }
        />
        {dis && (
          <>
            <div className="animate">
              <>
                {!then && anim.length > 0 && (
                  <p style={{ textAlign: "center" }}>Envoi encours</p>
                )}
                {!then && anim &&
                  anim.length > 0 &&
                  anim.map((anim) => {
                    return (
                      <h6 id="id" key={anim}>
                        o
                      </h6>
                    );
                  })}
              </>
            </div>
            {!then && (
              <div className="choice">
                <h1>Vous voulez acheter une formation ?</h1>
                <h2>Choisissez la ici !</h2>
                <figure>
                  <select onChange={(e) => setChoice(e.target.value)}>
                    <option value="back">
                      Formation back-end node js (10.000 fr)
                    </option>
                    <option value="front">
                      Formation front-end HTML CSS REACT JS (8500 fr){" "}
                    </option>
                    <option value="full">
                      {" "}
                      Formation complète Full stack (15.000 fr){" "}
                    </option>
                  </select>
                </figure>
                <button id="price" onClick={handleSubmit}>
                  Envoyer la demande
                </button>
              </div>
            )}
            {then && (
              <div className="choice">
                <h1 style={{fontSize:"16px"}}>Demande envoyée avec succès !<br/>
                  Nous vous contacterons pour la finalisation de votre inscription et <br/>paiement
                </h1>
                <img src="../images/suc.png" alt="suc-img" width='2%' />
                <button id="price" onClick={() => setThen(false)}>
                  Acheter une nouvelle formation
                </button>
              </div>
            )}
          </>
        )}
        {!dis && (
          <div className="orderDiv">
            <h3>Connectez-vous pour acheter une formation !</h3>
            <a href="/regester/signin">Cliquez ici pour vous connecter</a>
          </div>
        )}
      </>
    </div>
  );
};
