import React from "react";
import { SignleForm } from "./singleFormModel";

export const Mysql_js = () => {
  const nom = "Formation MYSQL nodeJs + JavaScript (back-end)";
  const prix = "10.000 Fr";
  const imag = "../images/not.png";
  const icon = "../images/img.svg";
  const description = `Deviens un expert du développement web en apprenant à utiliser
            JavaScript pour le front-end,
            Node.js pour le back-end, et MySQL pour gérer les bases de
            données. Ce que tu apprendras :
            Développer des sites web interactifs avec JavaScript 
            Créer des applications server-side avec Node.js 
            Gérer efficacement des bases de données avec MySQL`;

  const img = "../images/js.png";

  return (
    <>
      <SignleForm
        nom={nom}
        prix={prix}
        programme={
          <ul>
            <li>
              <img src={icon} alt="pic-video" /> Introduction aux bases de JavaScript
            </li>
            <li>
              <img src={icon} alt="pic-video" /> Manipulation du DOM
            </li>
            <li>
              <img src={icon} alt="pic-video" /> Gestion des événements
            </li>
            <li>
              <img src={icon} alt="pic-video" /> Introduction à Node.js
            </li>
            <li>
              <img src={icon} alt="pic-video" /> Création de serveurs avec Node.js
            </li>
            <li>
              <img src={icon} alt="pic-video" /> Introduction à Express.js
            </li>
            <li>
              <img src={icon} alt="pic-video" /> Gestion des routes et middleware
            </li>
            <li>
              <img src={icon} alt="pic-video" /> Introduction à MySQL
            </li>
            <li>
              <img src={icon} alt="pic-video" /> Création et gestion de bases de données
            </li>
            <li>
              <img src={icon} alt="pic-video" /> Requêtes SQL (SELECT, INSERT, UPDATE, DELETE)
            </li>
            <li>
              <img src={icon} alt="pic-video" /> Intégration de Node.js avec MySQL
            </li>
            <li>
              <img src={icon} alt="pic-video" /> Sécurisation des applications web
            </li>
            <li>
              <img src={icon} alt="pic-video" /> Gestion des sessions et des cookies
            </li>
            <li>
              <img src={icon} alt="pic-video" /> Création d'une API RESTful
            </li>
            <li>
              <img src={icon} alt="pic-video" /> Déploiement d'applications web
            </li>
            <li>
              <img src={icon} alt="pic-video" /> Projet final : Développement d'une application complète
            </li>
          </ul>
        }
        description={description}
        img={img}
        imag={imag}
        aper={null}
      />
    </>
  );
};
