import React, { useEffect, useState } from "react";
import axios from "axios";
import { Alert } from "./alert";

export const InscriptionFormAdmin = () => {
  const [prenom, setPrenom] = useState("");
  const [nom, setNom] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [mailmess, setMailmess] = useState("");
  const [numero, setNumero] = useState(null);
  const [disp , setDisp] = useState(false)
  const [passconfirm, setPassconfirm] = useState("");
  const [confirmerr, setConfirmerr] = useState(false);
  const [passerr, setPasserr] = useState(false);
  const [mailerr, setMailerr] = useState(false);
  const [numerr, setNumerr] = useState(false);
  const [anim, setAnim] = useState([]);
  const [start, setStart] = useState(false);
  const [dis, setDis] = useState(false);

  const Char = /[!*&()+_|?/><'"~;@#$%^m,`.]+/g;
  const Mail = /[@]/;
  const letter = /[a-b]/;

  function ConfirmData() {
    if (password !== '' && password !==null && passconfirm !== password) {
      setConfirmerr(true);
    } else {
      setConfirmerr(false);
    }
    const passVerify = Char.test(password);
    if (passVerify == true && password.length > 6) {
      setPasserr(false);
    } else {
      setPasserr(true);
    }
    if(email !== null && email !== ''){
      const mailVerify = Mail.test(email);

      if (mailVerify !== true) {
        setMailerr(true);
      } else {
        setMailerr(false);
      }    
    }

    const numLength = String(numero);
    if(numero !== '' && numero !== null){
      if (numLength.length >= 9 && numLength.length <= 14) {
        const numTest = letter.test(numero.toLowerCase());
        if (numTest == true) {
          setNumerr(true);
        } else {
          setNumerr(false);
        }
      }else{
        setNumerr(true)
      }
    }
  }

  const Submit = (event) => {
    setStart(true);
    event.preventDefault();

    axios
      .post(
        `${process.env.REACT_APP_API_URL_BACK}/submit/form/signin/form/admin`,
        {
          prenom: prenom,
          nom: nom,
          numero: numero,
          email: email,
          password: password,
        },
        { withCredentials: true }
      )
      .then((res) => {
        localStorage.setItem("Nom", nom);
        localStorage.setItem("Prenom", prenom);
        localStorage.setItem("Email", email);
        localStorage.setItem("Number", numero);
        setTimeout(() => {
          window.location.href = `${process.env.REACT_APP_API_URL}/`;
        }, 1000);
      })
      .catch((err) => {
        if 
        (
          err.response &&
          err.response.data &&
          err.response.data.Message &&
          err.response.data.Message.includes("mail")) {
          setMailmess(err.response.data.Message);
        } else {
          setMailmess("");
        }
        setPasserr("Une erreur est survenue");
        setDisp(true)
        setStart(false);
        setAnim([]);
      });

  };

  let j = { number: 0 };
  const array = [];

  useEffect(() => {
    ConfirmData()
    if (numerr || passerr || mailerr || confirmerr) {
      setDis(true);
    } else {
      setDis(false);
    }
  });

  useEffect(
    () => {
      if (start) {
        for (let i = 0; i <= 7; i++) {
          setTimeout(() => {
            if (i == 5) {
              j.number = 1;
            }
            array.push(i);
            setAnim([...array]);
          }, 1000 * i);
        }
      }

      return () => {
        setStart(null);
        setAnim([]);
      };
    },
    [start],
    [j.number]
  );

  return (
    <>
      <Alert
        message={"401 NON AUTHORISÉ !"}
        titre={"Erreur"}
        sous={"Une erreur est survenue "}
        dis={disp}
        button={
          <button
            class="close-btn"
            onClick={() => {
             setDisp(false)
            }}
          >
            Fermer
          </button>
        }
      />
      <form onSubmit={Submit}>
        <div className="form" id="contact">
          <div className="first">
            <h3 id="h1">Créez votre compte !</h3>
            <br />
            <br />
            <label htmlFor="prenom">Prenom</label> <br />
            <input
              type="text"
              required
              placeholder="Entrez votre prenom"
              name="prenom"
              onChange={(e) => setPrenom(e.target.value)}
            />
            <br />
            <label htmlFor="nom">Nom</label> <br />
            <div className="animate">
              <>
                {anim.length > 0 && <p>Chargement</p>}
                {anim &&
                  anim.length > 0 &&
                  anim.map((anim) => {
                    return (
                      <h6 id="id" key={anim}>
                        o
                      </h6>
                    );
                  })}
              </>
            </div>
            <input
              type="text"
              required
              placeholder="Entrez votre nom"
              name="nom"
              onChange={(e) => setNom(e.target.value)}
            />
            <br />
            <label htmlFor="Contact">Votre numéro</label>
            <br />
            <input
              type="tel"
              required
              name="numero"
              placeholder="Entrez votre numero"
              onChange={(e) => setNumero(e.target.value)}
            />
            <br />
            {numerr && <p className="error">Numéro incorrecte</p>}
            <label htmlFor="email">Adresse mail</label>
            <br />
            <input
              type="text"
              required
              name="email"
              placeholder="Votre adresse email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <p className="error">{mailmess}</p>
            {mailerr && <p className="error">Le mail saisi est incorrecte</p>}
          </div>
          <br />
          <h1></h1>
          <div className="area">
            <label htmlFor="password">Votre mot de passe </label>
            <br />
            <input
              type="password"
              required
              placeholder="Entrez votre mot de pass"
              name="password"
              onChange={(e) => setPassword(e.target.value)}
            />
            {passerr && (
              <p className="error">
                Le mot de passe doit contenir au minimum 6 caractères avec au moins un caractère spécial
                (!*&()+_|?/'"~;@#$%^m,`.) 
              </p>
            )}
            <br />
            <label htmlFor="confirm">Confirmez le mot de passe</label>
            <br />
            <input
              type="password"
              required
              placeholder="Confirmez le mot de pass ici"
              onChange={(e) => setPassconfirm(e.target.value)}
            />
            <br />

            {confirmerr && (
              <p className="error"> Mot de passe non conformes </p>
            )}
            <br />
            {!dis && (
              <input
                type="submit"
                id="submit"
                value="Envoyer"
              
              />
            )}
          </div>
        </div>
      </form>
    </>
  );
};
