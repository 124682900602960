import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { Alert } from "./alert";
import { useNavigate } from "react-router-dom";
export const AdminLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passerr, setPasserr] = useState("");
  const [mailerr, setMailerr] = useState("");
  const [generalerr, setGeneralerr] = useState("");
  const [anim, setAnim] = useState([]);
  const [start, setStart] = useState(false);
  const [dis , setDis] = useState(false)
  const [prenom , setPrenom] = useState('')
  const navigate = useNavigate()

  function nav(link){
    navigate(`${link}`)
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    setStart(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL_BACK}/submit/form/login/form/admin/account`,
        {
          email: email,
          password: password,
        },
        { withCredentials: true }
      )

      .then((res) => {
        setDis(true)
        localStorage.setItem("ID", res.data.id);
      })

      .catch((err) => {
          setGeneralerr(
            "Une erreur est survenue actualisez la page et ressayez !"
          );
      });
  };
  function Res (){
    nav('/home')
  }
  let j = { number: 0 };
  const array = [];
  useEffect(
    () => {

      if (start) {
        for (let i = 0; i <= 7; i++) {
          setTimeout(() => {
            if (i == 5) {
              j.number = 1;
            }
            array.push(i);
            setAnim([...array]);
          }, 1000 * i);
        }
      }
      return () => {
        setStart(null);
        setAnim([]);
      };
    },
    [start],
    [j.number]
  );
  useEffect(()=>{
    setPrenom(
      localStorage.getItem('Prenom')
    )
  })
  return (
    <>
      <Alert
        message={''}
        titre={`Salut `}
        sous={'Bienvenue !'}
        dis={dis}
        button={
          <button
            class="close-btn"
            onClick={() => {
             setDis(false)
             Res()
            }}
          >
            Continuez
          </button>
        }
        img={true}
      />
      <form onSubmit={handleSubmit} id="form-contact">
        <div className="form-contact-div" id="form-contact-div">
          <div className="form-contact-div-first" id="form-contact-div-p">
            <div className="animate">
              <>
                {anim.length > 0 && (
                  <p style={{ textAlign: "center" }}>Connexion</p>
                )}
                {anim &&
                  anim.length > 0 &&
                  anim.map((anim) => {
                    return (
                      <h6 id="id" key={anim}>
                        o
                      </h6>
                    );
                  })}
              </>
            </div>
            <h3 id="h1">Connectez-vous !</h3>
            <br />
            <label htmlFor="email">Adresse mail</label>
            <br />
            <input
              type="text"
              required
              name="email"
              placeholder="Votre adresse email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <p style={{ color: "red" }} id="passerr">
              {mailerr}
            </p>
            <br />
            <label htmlFor="password">Votre Mot de passe*</label>
            <br />
            <input
              type="text"
              required
              placeholder="Entrez votre mot de passe"
              name="password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <p style={{ color: "red" }} id="passerr">
              {passerr}
            </p>
            <br />
            <p style={{ color: "red" }} id="passerr">
              {generalerr}
            </p>
            <input type="submit" id="submit" value="Se connecter" />
            <br />
            <a href="/regester/login" style={{ color: "red" }}>
              Créez un compte
            </a>
          </div>
        </div>
      </form>
    </>
  );
};
