export const SendCookie = (message)=>({
    type:'SET_MESSAGE',
    payload:message
})

export const SendMedia = (media)=>({
    type:'SET_MEDIA',
    payload:media
})

export const  SendComment = (comment)=>({
    type:'SET_COMMENT',
    payload:comment
})