import React from "react";

export const Section = ({text_1,text_2,text_3,Id}) => {
  
  return (
    <div className="main" id={Id} >
      <div className="section">
        <h1>DEVENIR  <span>DEVELOPPEUR  RENOME</span></h1>
        <div className="p">
        <p>
            {text_1}<br/>
            {text_2}<br/>
            {text_3}
        </p>
        </div>
      </div>
    </div>
  );
};
