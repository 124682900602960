import React from "react";
import { useNavigate } from "react-router-dom";

export const Products = () => {
  const navigate = useNavigate()

  function nav(link){
    navigate(`${link}`)
  }
  return (
    <>
      <div className="text">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 640 512"
          style={{ width: "10%" }}
        >
          <path
            fill="#eef0f6"
            d="M392.8 1.2c-17-4.9-34.7 5-39.6 22l-128 448c-4.9 17 5 34.7 22 39.6s34.7-5 39.6-22l128-448c4.9-17-5-34.7-22-39.6zm80.6 120.1c-12.5 12.5-12.5 32.8 0 45.3L562.7 256l-89.4 89.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l112-112c12.5-12.5 12.5-32.8 0-45.3l-112-112c-12.5-12.5-32.8-12.5-45.3 0zm-306.7 0c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3l112 112c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256l89.4-89.4c12.5-12.5 12.5-32.8 0-45.3z"
          />
        </svg>
        <h1 id="para">Découvrez des formations de qualités 💻</h1>
        <p>
          Devenir <span> développeur/développeuse Full stack </span> et réussi
          (e) , <br /> apprendre à manipuler de grandes quantités de données,
          gérer des bases de <br />
          données assurer la disponibilité des datas , <br /> assurer les
          connexions et déconnexions des utilisateurs, <br /> crypter et
          décrypter des informations sensibles, <br /> hachage de mot de passe
          interaction avec le front-end <br /> react , concevoir des
          applications web robustes et solides🖥💻⚙
        </p>
      </div>
      <div className="products">
        <div className="product">
          <h1>
            Formation Back-end <br />
            nodeJs
          </h1>
          <img src="./images/node-js.png" alt="python" />
          {/*<p>
            Apprends à gérer des bases de données avec MySQL <br /> et à
            développer des applications back-end robustes avec Node.js.
            <br /> Cette formation te permettra de maîtriser l'interaction entre
            serveur et base de données. <br /> Ce que tu apprendras :<br />
            Créer et gérer des bases de données relationnelles avec MySQL <br />
            Développer des applications performantes avec Node.js
            <br />
            Connecter et manipuler les données de manière efficace
          </p>*/}
          <div className="butt">
          <a>prix:10.000 Francs</a>
          <a href="" onClick={(e)=>{
            e.preventDefault()
            nav('/mysql_node')
          }}>Voir les details ou l'acheter ici</a>
          </div>
        </div>
        <div className="product">
          <h1>
            Formation front-end HTML CSS REACT JS
          </h1>
          <img src="./images/pro.png" alt="python" />
          {/*<p>
            Maîtrise les bases du développement web et apprends à créer des{" "}
            <br />
            interfaces utilisateur modernes avec HTML, CSS et React.js ! <br />
            Ce que tu apprendras : Structurer des pages web avec HTML <br />{" "}
            Styliser et rendre tes sites interactifs avec CSS <br />
            Créer des interfaces dynamiques et performantes avec React.js <br />{" "}
            Formation en ligne flexible, avec projets pratiques
            <br />
            <br />
            <br />
            <br />
          </p>*/}
          <div className="butt">
          <a>prix:8.500 Francs</a>
          <a href="" onClick={(e)=>{
            e.preventDefault()
            nav('/html_react')
          }}>Voir les details ou l'acheter ici</a>
          </div>
        </div>
        <div className="product">
          <h1>
            Formation MYSQL <br /> nodeJs + javaScript (back-end)
          </h1>
          <img src="./images/js.png" alt="python" />
          {/*<p>
            Deviens un expert du développement web en apprenant à utiliser
            JavaScript pour le front-end,
            <br /> Node.js pour le back-end, et MySQL pour gérer les bases de
            données. Ce que tu apprendras :<br />
            Développer des sites web interactifs avec JavaScript <br />
            Créer des applications server-side avec Node.js <br />
            Gérer efficacement des bases de données avec MySQL
          </p>*/}
          <div className="butt">
          <a>prix:10.000 Francs</a>
          <a href="" onClick={(e)=>{
            e.preventDefault()
            nav('/mysql_js')
          }}>Voir les details ou l'acheter ici</a>
          </div>
        </div>
        <div className="product">
          <h1>
            Formation complète <br /> Full stack
          </h1>
          <img src="./images/computer.png" alt="python" />
          {/*<p>
            Deviens un développeur full stack complet <br /> en maîtrisant le
            front-end et le back-end avec les technologies les plus demandées.
            🎯 Ce que tu apprendras :<br />
            Front-end : Création d’interfaces utilisateurs dynamiques avec HTML,
            CSS, JavaScript et React.js
            <br />
            Back-end : Développement d’applications robustes avec Node.js et
            gestion de bases de données avec MySQL
            <br />
            API REST, sécurité et optimisation des performances
          </p>*/}
          <div className="butt">
          <a>prix:15.000 Francs</a>
          <a href="" onClick={(e)=>{
            e.preventDefault()
            nav('/full')
          }}>Voir les details ou l'acheter ici</a>
          </div>
        </div>
      </div>
    </>
  );
};
