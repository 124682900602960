import React from "react";

export const Alert = ({ titre, sous, message, dis , button , img}) => {
  return (
    <>
      {dis && (
        <>
          <div className="custom-alert" id="customAlert">
            <div className="alert-box">
              <h3 id="h22" style={{textAlign:"center"}}>{titre} {img ? <img src="../images/bye.png" alt=""/>  : '' }  </h3>
              <p>{sous}</p>
              <p>{message}</p>
                {button}
            </div>
          </div>
        </>
      )}
    </>
  );
};
