import React from "react";

export const ExosSubmit = ({ dis , res , change}) => {
  return (
    <>
      {dis && (
        <>
          <div class="custom-alert" id="customAlert">
            <div class="alert-box">
              <h2 id="h22">Envoyer un exo  </h2>
                {change}
                {res}
            </div>
          </div>
        </>
      )}
    </>
  );
};
