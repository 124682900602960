import React from "react";
import { SignleForm } from "./singleFormModel";

export const Mysql_node = () => {
  const nom = "Formation MYSQL-NODE JS back-end";
  const prix = "10.000 Fr";
  const icon = '../images/img.svg';
  const imag = "../images/not.png";
  const description = `Apprends à gérer des bases de données avec MySQL 
            et à développer des applications back-end robustes avec Node.js.
            Cette formation te permettra de maîtriser l'interaction entre
            serveur et base de données. Ce que tu apprendras :
            Créer et gérer des bases de données relationnelles avec MySQL 
            Développer des applications performantes avec Node.js
            Connecter et manipuler les données de manière efficace.`;
  const img = "../images/node-js.png";

  return (
    <>
      <SignleForm
        nom={nom}
        prix={prix}
        programme={
          <ul>
            <li><img src={icon} alt="pic-video"/> Rappels sur les bases HTML</li>
            <li><img src={icon} alt="pic-video"/> Les balises 'p', 'h', 'a', 'div', 'ul'</li>
            <li><img src={icon} alt="pic-video"/> Les balises 'li', 'span', les class/id, les formulaires</li>
            <li><img src={icon} alt="pic-video"/> Les variables (var, const, let)</li>
            <li><img src={icon} alt="pic-video"/> Le console</li>
            <li><img src={icon} alt="pic-video"/> JavaScript : Bases</li>
            <li><img src={icon} alt="pic-video"/> Les fonctions</li>
            <li><img src={icon} alt="pic-video"/> Les conditions, les boucles</li>
            <li><img src={icon} alt="pic-video"/> Les tableaux</li>
            <li><img src={icon} alt="pic-video"/> Les conversions de chaîne</li>
            <li><img src={icon} alt="pic-video"/> Initialisation projet React</li>
            <li><img src={icon} alt="pic-video"/> Syntaxe JSX et style CSS</li>
            <li><img src={icon} alt="pic-video"/> Hook : useState, useEffect, useContext, useSelector</li>
            <li><img src={icon} alt="pic-video"/> Propriétés onClick, onChange, validation formulaire</li>
            <li><img src={icon} alt="pic-video"/> Liaisons des pages, <hr/> et <br/></li>
            <li><img src={icon} alt="pic-video"/> Routes et fonctions return</li>
            <li><img src={icon} alt="pic-video"/> Introduction à Node.js</li>
            <li><img src={icon} alt="pic-video"/> Introduction à Axios, envoi de données et gestion de connexion</li>
            <li><img src={icon} alt="pic-video"/> Créer et gérer des bases de données relationnelles avec MySQL</li>
            <li><img src={icon} alt="pic-video"/> Développer des applications performantes avec Node.js</li>
            <li><img src={icon} alt="pic-video"/> Connecter et manipuler les données de manière efficace</li>
          </ul>
        }
        description={description}
        img={img}
        imag={null}
        aper={true}
      />
    </>
  );
};
