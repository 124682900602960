import React, {  useState } from 'react';
import { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {legacy_createStore as createStore} from 'redux'
import App from './App';
import { CookiesReducer } from './components/reducers/reducer';
import { Provider } from 'react-redux';
import { combineReducers } from 'redux';

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('../public/firebase-messaging-sw.js')
    .then((registration) => {
      //console.log('Service Worker enregistré avec succès:', registration);
    })
    .catch((err) => {
      //console.log('Erreur lors de l\'enregistrement du Service Worker:', err);
    });
}

const red = combineReducers({
   reducer: CookiesReducer
})

const store = createStore(red)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}> 
    <App />
  </Provider>
);
