import React, { useEffect } from "react";
import { Homeheader } from "./homeHeader";
import { Section } from "./main";
import { ProductHome } from "./productHome";
import { About } from "./presentation";
import { Form } from "./form";
import { Footer } from "./footer";
import { Alert } from "./alert";
import { useState } from "react";
import { Chat } from "./chat";
import { useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export const Home = () => {
  const [dis, setDis] = useState(true);
  const allow = useSelector((state) => state.reducer.cookie);
  const [data, setData] = useState();
  const navigate = useNavigate()

  function nav(link){
    navigate(`${link}`)
  }
  let text_1 =
    "Confiez-nous vos projets web sur mesure, tous types de sites et sécurité incluse. Explorez nos";
  let text_2 =
    "formations (Node.js, back-end), rejoignez une communauté de développeurs et publiez vos projets.";
  let text_3 =
    "Nous sommes à l'avant-garde de l'innovation numérique au Sénégal.";

  useEffect(() => {
    setData(allow);
  });



  return (
    <>
      {!data && (
        <Alert
          message={
            <>
              <br />
              <div className="menu-links">
                <a href="" onClick={(e)=>{
                  e.preventDefault()
                  nav('/freecourses')
                }}>
                  Accéder aux formations gratuites
                </a>
                <a href="" onClick={(e)=>{
                  e.preventDefault()
                  nav('/espace')
                }}>
                  Accéder à mon espace
                </a>
                <a href="" onClick={(e)=>{
                  e.preventDefault()
                  nav('/formations')
                }}>
                  Accéder aux formations avancées payantes pas chères
                </a>
              </div>
              <br />
            </>
          }
          titre={`Bienvenue !`}
          sous={
            "Vous n'avez pas encore de compte ? créez-en un maintenant ou connectez-vous pour rejoindre nos formations gratuites"
          }
          dis={dis}
          button={
            <div className="chil">
              <p
                onClick={() => {
                  setDis(false);
                }}
              >
                Fermer
              </p>
              <p
                onClick={() => {
                  nav('/regester/signin')
                }}
              >
                Me connecter
              </p>
              <p
                onClick={() => {
                  nav('/regester/login')
                }}
              >
                Créer un compte
              </p>
            </div>
          }
          img={true}
        />
      )}
      <Homeheader props={true} />
      <Section text_1={text_1} text_2={text_2} text_3={text_3} />
      <ProductHome />
      <Form />
      <About />
      <Footer />
    </>
  );
};
