import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { Alert } from "./alert";
import { useNavigate } from "react-router-dom";

export const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passerr, setPasserr] = useState("");
  const [mailerr, setMailerr] = useState("");
  const [generalerr, setGeneralerr] = useState("");
  const [anim, setAnim] = useState([]);
  const [start, setStart] = useState(false);
  const [dis, setDis] = useState(false);
  const [prenom, setPrenom] = useState("");
  const navigate = useNavigate()

  function nav(link){
    navigate(`${link}`)
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    setStart(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL_BACK}/submit/form/login/form`,
        {
          email: email,
          password: password,
        },
        { withCredentials: true }
      )

      .then((res) => {
          setDis(true);
        Cookies.set(
          "InscriptionCookie",
          `${res.data.id + Math.random + Date.now() + "XKWMNA%2BXJZKSUREKSLP"}`,
          { secure: true, sameSite: "Strict" }
        );
        Cookies.set(
          "ConnexionCookie",
          `${
            res.data.id +
            Math.random +
            Date.now() +
            "XCNAOAWKWNCS;ZW0_NA%2BXJZKSUREKSLP"
          }`,
          { secure: true, sameSite: "Strict" }
        );
        localStorage.setItem("Prenom", res.data.prenom);
        localStorage.setItem("Nom", res.data.nom);
        localStorage.setItem("Email", res.data.email);
        localStorage.setItem("Number", res.data.number);
        localStorage.setItem("id", res.data.id);
        localStorage.setItem('tok','success')
      })

      .catch((err) => {
        if (err.response && err.response.data) {
          if (err.response.data.Message.includes("pass")) {
            setPasserr(err.response.data.Message);
            setMailerr("");
          } else if (err.response.data.Message.includes("Email")) {
            setMailerr(err.response.data.Message);
            setPasserr("");
          } else {
            setMailerr("");
            setPasserr("");
          }
        } else {
          setGeneralerr(
            "Une erreur est survenue actualisez la page et ressayez !"
          );
        }
      });
  };
  function Res() {
    window.location.href='/home'
  }
  let j = { number: 0 };
  const array = [];
  useEffect(
    () => {
      if (start) {
        for (let i = 0; i <= 7; i++) {
          setTimeout(() => {
            if (i == 5) {
              j.number = 1;
            }
            array.push(i);
            setAnim([...array]);
          }, 1000 * i);
        }
      }

      return () => {
        setStart(null);
        setAnim([]);
      };
    },
    [start],
    [j.number]
  );
  useEffect(() => {
    setPrenom(localStorage.getItem("Prenom"));
  });
  return (
    <>
      <Alert
        message={
        <>
          <p>.......</p>
        </>
        }
        titre={`Salut ${prenom}`}
        sous={"Bienvenue !"}
        dis={dis}
        button={
          <button
            class="close-btn"
            onClick={() => {
              setDis(false);
              Res();
            }}>
            Menu principal
          </button>
        }
        img={true}
      />
      <form onSubmit={handleSubmit} id="form-contact">
        <div className="form-contact-div" id="form-contact-div">
          <div className="form-contact-div-first" id="form-contact-div-p">
            <div className="animate">
              <>
                {anim.length > 0 && (
                  <p style={{ textAlign: "center" }}>Connexion</p>
                )}
                {anim &&
                  anim.length > 0 &&
                  anim.map((anim) => {
                    return (
                      <h6 id="id" key={anim}>
                        o
                      </h6>
                    );
                  })}
              </>
            </div>
            <h3 id="h1">Connectez-vous !</h3>
            <br />
            <label htmlFor="email">Adresse mail</label>
            <br />
            <input
              type="email"
              required
              name="email"
              placeholder="Votre adresse email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <p style={{ color: "red" }} id="passerr">
              {mailerr}
            </p>
            <br />
            <label htmlFor="password">Votre Mot de passe*</label>
            <br />
            <input
              type="password"
              required
              placeholder="Entrez votre mot de passe"
              name="password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <p style={{ color: "red" }} id="passerr">
              {passerr}
            </p>
            <br />
            <p style={{ color: "red" }} id="passerr">
              {generalerr}
            </p>
            <input type="submit" id="submit" value="Se connecter" />
            <br />
            <div
              className="liens"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <a href="" onClick={(e)=>{
                e.preventDefault()
                nav('/regester/login')
              }}>
                Créez un compte
              </a>
              <a href="" onClick={(e)=>{
                e.preventDefault()
                nav('/regester/forgetpass')
              }} >
                Mot de passe oublié
              </a>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
