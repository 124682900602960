import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { SendCookie } from "./actions/action";
import { Avis } from "./avis";



export const Chat = () => {
    const [dis,setDis] = useState(false) 
    const [aff,setAff] = useState(false)
  
    return (
    <>
      <button className="chat-btn" onClick={()=>setDis(true)}><img src="../images/ass.png"/></button>
      <Avis avis={aff} onClick={()=>setAff(false)}/>
     {dis && (
        <>
        <div className="chat-alert">
        <div className="chat-box">
          <div className="chat">
            <h1>Salut</h1>
            <h3>Avant tout cré ton compte ensuite connectez-vous</h3>
            <p>
              <span>01</span> Tu veux créer ton compte,
             cliquez sur ce lien:{" "}
              <a href={`${process.env.REACT_APP_API_URL}/regester/login`}>
                Créez mon compte
              </a>
            </p>
            <p>
              <span>02</span> Tu veux te connecter,
             cliquez sur ce lien:{" "}
              <a href={`${process.env.REACT_APP_API_URL}/regester/signin`}>
                Me connecter
              </a>
            </p>
            <p>
              <span>03</span> Tu veux voir les cours gratuits,
             cliquez sur ce lien:{" "}
              <a href={`${process.env.REACT_APP_API_URL}/freecourses`}>
                Voir cours gratuits
              </a>
            </p>
            <p>
              <span>04</span> Vous voulez acheter une formation,
              cliquez sur ce lien,ensuite choisissez une formation{" "}
              <a href={`${process.env.REACT_APP_API_URL}/formations`}>
                Acheter une formation
              </a>
            </p>
            <p>
              <span>05</span> Vous voulez accéder à votre compte
               cliquez sur ce lien{" "}
              <a href={`${process.env.REACT_APP_API_URL}/espace`}>
                Mon compte
              </a>
              </p>
              <p>
              <span>06</span> Votre avis compte 
               pour nous signaler un probléme <br/> ou vous voulez   qu'on améliore ou  ajoute quelque chose  , cliquez ici {" "}
              <a href="" onClick={(e)=>{
                e.preventDefault()
                setDis(false)
                  setAff(true)
                }}>
                Donner un avis
              </a>
              </p>
          </div>
          <button className="close-btn" onClick={()=>setDis(false)}>Fermer</button>
        </div>
      </div>
        </>
     )}
    </>
  );
};
