import React from "react";
import { Contact } from "./contact";
import { NavBar } from "./navbar";
import { useNavigate } from "react-router-dom";
export const Homeheader = ({props}) => {

  const navigate = useNavigate()

  function nav(link){
    navigate(`${link}`)
  }
  return (
    <>
      <div className="head">
        <div className="header">
        <div>
        <h1 id="logo" style={{cursor:'pointer'}} onClick={()=>{
          nav('/home')
        }}>
            INFORMATIQUES <span> EN WOLOF{`</>`} </span>
            <p style={{fontSize:"13px"}}>Du code pour chacun, de l'impact pour tous {`</>`}</p>
          </h1>
        </div>
          <Contact />
        </div>
        <div className="nav">
          <NavBar props={props} />
        </div>
      </div>
    </>
  );
};
