import axios from "axios";

export const sendMailNotif = async (option,message,sujet,destinataires)=>{
        let data = {
            otpion:option,
            message:message,
            sujet:sujet,
            destinataires:destinataires
        }
        await axios({
            method:'post',
            data:data,
            withCredentials:true,
            url:`${process.env.REACT_APP_API_URL_BACK}/submit/${1}/admin/send/mess`
        })
        .then((res)=>{
            alert('Message envoyé avec succès')
        })
        .catch((err)=>{
            alert('Une erreur est survenue !')
            console.log(err)
        })
}

