import React from "react";

export const SendMess = ({ dis , res , change , cancel,err}) => {
  return (
    <>
      {dis && (
        <>
          <div class="custom-alert" id="customAlert">
            <div class="alert-box">
              <h2 id="h22">Envoyer un message  </h2>
              <p> Minimum 30 caractères</p>
              <p className="error">{err}</p>
                {change}
                {res}
                {cancel}
            </div>
          </div>
        </>
      )}
    </>
  );
};
