import React from "react";

export const Contact = ()=>{
    return(
        <>
            <div className="contact">
                <div className="cont">
                    <i class="fa fa-envelope" ></i>
                    <a href="mailto:admin@sen-teranga.com">Email</a>
                </div>
                <div className="cont">
                <i class="fa fa-whatsapp" ></i>
                    <a href="https://wa.me/221704291214">Whatsapp</a>
                </div>
                <div className="cont">
                <p id="fas" onClick={()=>{
                    document.querySelector('#nav')
                    .classList
                    .add('active')
                }}><i class="material-icons">&#xe5d2;</i></p>
                <a></a>
                </div>
            </div>
        </>
    )
}