import React, { useEffect, useState } from "react";
import axios from "axios";
import { Alert } from "./alert";
export const Form = () => {
  const [csurf, setCsurf] = useState(null);
  const [prenom, setPrenom] = useState("");
  const [nom, setNom] = useState("");
  const [number, setNumber] = useState("");
  const [then, setThen] = useState(false);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [anim, setAnim] = useState([]);
  const [start, setStart] = useState(false);
  const [disp, setDisp] = useState(false);
  const [err, setErr] = useState();
  const [h1, setH1] = useState("Erreur");
  const [h2, setH2] = useState("Une erreur est survenue ");
  const [article, setArticle] = useState(
    "Actualisez la page et ressayez dans un instant !"
  );
  const handleSubmit = (e) => {
    e.preventDefault();
    setStart(true);

    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL_BACK}/submit/mess/send/nologin`,
      data: {
        prenom: prenom,
        nom: nom,
        numero: number,
        email: email,
        message: message,
      },
      withCredentials: true,
    })
      .then((res) => {
        setTimeout(() => {
          setThen(true);
        }, 3000);
      })
      .catch((err) => {
        setAnim(false);
        if (
          err.response &&
          err.response.data &&
          err.response.data.Message.includes("messages")
        ) {
          setH1("........");
          setArticle(
            "Vous ne pouvez pas envoyé plus de 3 messages pour le moment"
          );
        }
        setDisp(true);
        if (
          err.response &&
          err.response.data &&
          err.response.data.err &&
          err.response.data.err.name === "TokenExpiredError"
        ) {
          setErr("Session perdue reconnectez-vous !");
        } else {
          setErr("Actualisez la page et ressayez dans un instant !");
        }
      });
  };
  const array = [];
  useEffect(() => {
    if (start) {
      for (let i = 0; i <= 7; i++) {
        setTimeout(() => {
          array.push(i);
          setAnim([...array]);
        }, 1000 * i);
      }
    }

    return () => {
      setStart(null);
      setAnim([]);
    };
  }, [start]);
  return (
    <>
      <Alert
        message={article}
        titre={h1}
        sous={h2}
        dis={disp}
        button={
          <button
            class="close-btn"
            onClick={() => {
              setDisp(false);
              //     window.location.href = "/regester/signin";
            }}
          >
            Fermer
          </button>
        }
      />
      {then && (
        <div className="choice">
          <h1>Message Envoyé Avec Succès !</h1>
          <img src="../images/suc.png" alt="suc-img" />
          <button id="price" onClick={() => setThen(false)}>
            Envoyer un nouveau message
          </button>
        </div>
      )}
      {!then && (
        <>
        <h1 style={{textAlign:'center'}}>Nous contactez</h1>
        <form onSubmit={handleSubmit} className="inscription-form-config" id="inscription-form">
          <div className="inscription-form" id="inscription-form-config">
            <div className="inscription-form-config">
              <label htmlFor="prenom">Prenom</label> <br />
              <input
                type="text"
                required
                placeholder="Entrez votre prenom"
                onChange={(e) => {
                  setPrenom(e.target.value);
                }}
              />
              <br />
              <label htmlFor="nom">Nom</label> <br />
              <input
                type="text"
                required
                placeholder="Entrez votre nom"
                onChange={(e) => {
                  setNom(e.target.value);
                }}
              />
              <br />
              <div className="animate">
                {anim && anim.length > 0 && (
                  <p style={{ textAlign: "center" }}>Envoi</p>
                )}
                {anim &&
                  anim.length > 0 &&
                  anim.map((anim) => {
                    return (
                      <h6 id="id" key={anim}>
                        o
                      </h6>
                    );
                  })}
              </div>
              <br />
              <label htmlFor="Contact">Votre numéro</label>
              <br />
              <input
                type="text"
                required
                placeholder="Entrez votre numero"
                onChange={(e) => {
                  setNumber(e.target.value);
                }}
              />
              <br /><br/>
              <label htmlFor="email">Adresse mail</label>
              <br />
              <input
                type="email"
                required
                placeholder="Votre adresse email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <br />
            <div className="area" >
              <label htmlFor="message">Votre message</label>
              <br />
              <textarea id="config-area" placeholder="Votre message ici" required onChange={(e) => setMessage(e.target.value)}>
                
              </textarea>
              <br />
              <input type="submit" id="submit" value="Envoyer le message" />
            </div>
          </div>
          <br/><br/>
        </form>
        </>
      )}
    </>
  );
};
