const initialState = {
    cookie:'',
    message:'',
    id:'',
    email:'',
    session: '',
    media:[],
    comment:[]
}


export const CookiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_MESSAGE':
            return {
                ...state, cookie: action.payload
            }
        case 'SET_MEDIA':
            return {
                ...state, media: action.payload
            }
        case 'SET_COMMENT':
            return{
                ...state,comment:action.payload
            }
        default:
            return state
    }
}


