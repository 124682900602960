import React, { useEffect, useState } from "react";
import axios from "axios";
import { ValidateOrder } from "./ValidateOrder";
import { sendMailNotif } from "./Options/mailNotif";

export const AdminDash = () => {
  const [titre, setTitre] = useState("");
  const [soustitre, setSoustitre] = useState("");
  const [file, setFile] = useState(null);
  const [description, setDescription] = useState("");
  const [messages, setMessages] = useState(null);
  const [orders, setOrders] = useState(null);
  const [dis, setDis] = useState(false);
  const [id, setId] = useState(null);
  const [email, setEmail] = useState("");
  const [progress, setProgress] = useState(0);
  const [numero, setNumero] = useState("");
  const [prenom, setPrenom] = useState("");
  const [free, setFree] = useState("free");
  const [nom, setNom] = useState("");
  const [langage, setLangage] = useState("html");
  const [user, setUser] = useState(null);
  const [count, setCount] = useState(null);
  const [ordered, setOrdered] = useState(null);
  const [second, setSecond] = useState(false);
  const [inc, setInc] = useState(0);
  const [incr, setIncr] = useState(0);
  const [name,setName] = useState('')
  // notif and mail notifs
  const [sujet,setSujet] = useState('')
  const [destinataires,setDestinataires] = useState('allUser')
  const [contenue,setContenue] = useState('')
  // end
  const ValidateOrderForm = (e) => {
    e.preventDefault();
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL_BACK}/submit/admin/order/validate/${id}`,
      withCredentials: true,
      data: {
        prenom: prenom,
        nom: nom,
        email: email,
        numero: numero,
        quotas: free,
        langage: langage,
        name:name
      },
    })
      .then((res) => {
        alert("Order validate success !");
        setDis(false);
      })
      .catch((err) => {
        //console.log()
        alert("Une erreur est survenue");
      });
  };

  const Submit = () => {
    const Data = {
      titre:titre,
      sous_titre:soustitre,
      description:description,
      numero:'289238',
      quotas:free,
      langage:langage
    }
    //for (let i = 0; i < file.length; i++) {
    //  Data.append("file", file[i]);
    //}

    axios
      .post(
        `${process.env.REACT_APP_API_URL_BACK}/submit/${id}/video/post`,
        Data,
        { withCredentials: true },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        alert("Data posted !");
        setProgress(0);
      })
      .catch((err) => {
        alert("Une erreur est survenue !");
      });
  };

  useEffect(() => {
    setId(localStorage.getItem("ID"));

    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL_BACK}/submit/admin/data/${id}`,
      withCredentials: true,
      data: { admin: "admin", numero: "0739210" },
    })
      .then((res) => {
        alert("Data getted !");
        setMessages(res.data[0]);
        setOrders(res.data[1]);
        setUser(res.data[2]);
        setOrdered(res.data[3]);
        setCount(res.data[4]);
      })
      .catch((err) => {
        alert("Une erreur est survenue !");
      });
  }, []);

  function sendNotif(){
    sendMailNotif('Notif',contenue,sujet,destinataires)
  }
  function sendMail(){
    sendMailNotif('Mail',contenue,sujet,destinataires)
  }

  return (
    <div className="admin">
      <>
        <ValidateOrder
          dis={dis}
          form={
            <>
              <div className="ValidateOrder">
                <label htmlFor="prenom">Prenom</label>
                <br />
                <input
                  type="text"
                  required
                  placeholder="Entrez son prenom"
                  name="prenom"
                  onChange={(e) => {
                    setPrenom(e.target.value);
                  }}
                />
                <br />
                <label htmlFor="prenom">Nom</label>
                <br />
                <input
                  type="text"
                  required
                  placeholder="Entrez son nom"
                  name="nom"
                  onChange={(e) => {
                    setNom(e.target.value);
                  }}
                />
                <label htmlFor="email">Adresse mail</label>
                <br />
                <input
                  type="text"
                  required
                  name="email"
                  placeholder="Son adresse email"
                  onChange={(e) => setEmail(e.target.value)}
                />

                <br />
                <label htmlFor="number">Numero</label>
                <br />
                <input
                  type="text"
                  required
                  placeholder="Entrez son numero"
                  name="numero"
                  onChange={(e) => {
                    setNumero(e.target.value);
                  }}
                />
                <br />
              </div>
            </>
          }
          action={() => setDis(false)}
          order={<button onClick={ValidateOrderForm}>Envoyer</button>}
        />
        <h3 id="h1">BIENVENUE ADMIN !</h3>
        <div className="user">
          <div className="users">
            <h2>
              Nombre d'utilisateur:{" "}
              <span style={{ color: "#2b2d42" }}>0{user ? user.user : ""}</span>
            </h2>
          </div>
          <div className="users">
            <h2>
              Nombre d'élèves inscrits:{" "}
              <span style={{ color: "#2b2d42" }}>
                0{count ? count.count : ""}
              </span>
            </h2>
          </div>
          <div className="users">
            <h2>
              Nombre de demandes formation{" "}
              <span style={{ color: "#2b2d42" }}>
                0{ordered ? ordered.order : ""}
              </span>
            </h2>
          </div>
        </div>
        <div className="flexbox">
          <div>
            <div className="" id="">
              <br />
              <button onClick={() => setSecond(!second)}>Second</button>
              <h2>Uploader une vidéo</h2>
              <label>Langages</label>
              <select onChange={(e) => setLangage(e.target.value)}>
                <option value="html">HTML</option>
                <option value="css">CSS</option>
                <option value="javascript">Javascript</option>
                <option value="reactjs">React js</option>
                <option value="mysql">MYSQL</option>
                <option value="python">Python</option>
                <option value="nodejs">Node Js</option>
              </select>
              <label>Quotas</label>
              <select onChange={(e) => setFree(e.target.value)}>
                <option value="free">Gratuit</option>
                <option value="premium">Premiuim</option>
              </select>
              <div className="">
                <label htmlFor="title">Titre de la vidéo</label> <br />
                <input
                  type="text"
                  required
                  placeholder="Entrez le titre"
                  name="title"
                  onChange={(e) => setTitre(e.target.value)}
                />
                <br />
                <label htmlFor="title">Nom video</label> <br />
                <input
                  type="text"
                  required
                  placeholder="Entrez le titre"
                  name="name"
                  onChange={(e) => setName(e.target.value)}
                />
                <br />

                {second && (
                  <>
                    <label htmlFor="title">Sous titre</label> <br />
                    <input
                      type="text"
                      required
                      placeholder="Entrez le sous titre de la video"
                      name="sous_title"
                      onChange={(e) => setSoustitre(e.target.value)}
                    />
                    <br />
                    <label>Description de la vidéo</label> <br />
                    <textarea onChange={(e) => setDescription(e.target.value)}>
                      Description
                    </textarea>{" "}
                    <br />
                    <input
                      className="file-input"
                      id="fileUploader"
                      required
                      type="file"
                      name="files"
                      onChange={(e) => setFile(e.target.files)}
                      multiple
                      maxLength="5"
                    />
                  </>
                )}
                <button onClick={Submit}>Poster</button>
              </div>
            </div>
          </div>
          <div>
            <div className="" id="">
              <br />
              <br />
              <h2>Vos Messages</h2>
              <button onClick={() => setIncr(incr + 1)}>next+</button>
              <button onClick={() => setIncr(incr - 1)}>prev-</button>
              {messages && messages[incr] && (
                <>
                  <h2>Prenom: {messages[incr].prenom}</h2>
                  <div className="">
                    <label htmlFor="prenom">Nom: {messages[incr].prenom}</label>{" "}
                    <br />
                    <br />
                    <label htmlFor="nom">
                      Numero: {messages[incr].contact}
                    </label>{" "}
                    <br />
                    <label htmlFor="email">Email: {messages[incr].email}</label>
                    <br />
                    <label htmlFor="message"> Message</label> <br />
                    <textarea
                      placeholder={messages[incr].messages}
                    ></textarea>{" "}
                    <br />
                  </div>
                </>
              )}
            </div>
          </div>
          <div>
            <div className="" id="">
              <br />
              <br />
              <h2>Vos demandes de formations</h2>
              <button onClick={() => setInc(inc + 1)}>next+</button>
              <button onClick={() => setInc(inc - 1)}>prev-</button>
              {orders && orders[inc] && (
                <>
                  <h2>Prenom: {orders[inc].prenom}</h2>
                  <div className="">
                    <label htmlFor="prenom">Nom: {orders[inc].nom}</label>{" "}
                    <br />
                    <br />
                    <label htmlFor="nom">
                      Numero: {orders[inc].contact}
                    </label>{" "}
                    <br />
                    <label htmlFor="email">Email: {orders[inc].email}</label>
                    <br />
                    <label htmlFor="message"> Formations</label> <br />
                    <textarea placeholder={orders[inc].type}></textarea> <br />
                    <button onClick={() => setDis(true)}>Valider</button>
                  </div>
                </>
              )}
            </div>
          </div>
          <div>
            <div className="" id="">
              <br />
              <br />
              <h2>Envoyer un mail aux utilisateurs</h2>
              <>
                <h2></h2>
                <div className="">
                  <label>Qui ?</label>
                  <br />
                  <select onChange={(e)=>setDestinataires(e.target.value)}>
                    <option value='allUser' >Tout le monde</option>
                    <option value='students'>Aux inscrits sur des formations</option>
                  </select>
                  <label htmlFor="prenom">Le sujet</label> <br />
                  <input type="text" placeholder="le sujet" onChange={(e)=>setSujet(e.target.value)} />
                  <br />
                  <label htmlFor="nom">Entrez le message</label> <br />
                  <textarea placeholder="Le message ici" onChange={(e)=>setContenue(e.target.value)}></textarea> <br />
                  <button onClick={sendMail}>Envoyer</button>
                </div>
              </>
            </div>
          </div>
          <div>
            <div className="">
              <h2>Envoyer une notification</h2>
              <label>Qui ?</label>
              <br />
              <select onChange={(e)=>setDestinataires(e.target.value)}>
                <option value='allUser' >Tout le monde</option>
                <option value='students'>Aux inscrits sur des formations</option>
              </select>
              <label htmlFor="prenom">Le sujet</label> <br />
              <input type="text" placeholder="le sujet"  onChange={(e)=>setSujet(e.target.value)}/>
              <br />
              <label htmlFor="nom">Entrez le message</label> <br />
              <textarea placeholder="Le message ici" onChange={(e)=>setContenue(e.target.value)}></textarea> <br />
              <button onClick={sendNotif}>Envoyer</button>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};
