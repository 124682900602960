import React from "react";

export const ValidateOrder = ({ dis , form , action , order}) => {
  return (
    <>
      {dis && (
        <>
          <div className="custom-alert" id="customAlert">
            <div className="alert-box">
              <h2> Valider l'inscription de cette personne </h2>
                {form}
                <button onClick={action}>Annuler</button>
                {order}
            </div>
          </div>
        </>
      )}
    </>
  );
};
