import React, { useState } from "react";
import { Homeheader } from "./homeHeader";
import { About } from "./presentation";
import { Form } from "./form";
import { Footer } from "./footer";
import { useNavigate } from "react-router-dom";
import { Order } from "./order";
export const SignleForm = ({
  nom,
  prix,
  description,
  programme,
  img,
  imag,
  aper,
}) => {
  return (
    <>
      <Homeheader props={false} />
      <div>
        <div className="product-content">
          <div className="product-content-child">
            <h1>{nom}</h1>
            <img src={img} alt="pic" id="pic" />
            <h2>Description</h2>
            <p className="new-line">{description}</p>
            <button id="price">{prix}</button>
          </div>
          <div className="product-content-child" id="product-child">
            <a href="" onClick={(e) => e.preventDefault()}>
              {aper
                ? "Apercu du cours"
                : "Apercu du cours non disponible pour le moment"}
            </a>
            {imag !== null ? (
              <img id="product-child-img" src={imag} alt="image" />
            ) : (
              <iframe
                height="315"
                src="https://www.youtube.com/embed/fh5T8SaAFYo?si=Gq_V4G4SHqwaCogs"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            )}
            <h1>Programme</h1>
            <ul className="new-line">{programme}</ul>
          </div>
        </div>
      </div>
      <br />
      <br />
      <Order />
      
      <Form />
      <br />
      <br />
    </>
  );
};
