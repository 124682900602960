
import './App.css';
import { Chat } from './components/chat';
import { Main } from './routes/route';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import './comcss/com.css'

let expConfig

function App() {
  const [config,setConfig] = useState(null)
  const [vapid,setVapid] = useState('')
  const [go,setGo] = useState(false)
  function requestPermission() {

    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        console.log("");
      } else {
        console.log("");
      }
    });
  }
  requestPermission();

  const Allow = ()=>{
    let firebaseConfig  =  config
    let    app = initializeApp(firebaseConfig)
    let messaging   = getMessaging(app)

    getToken(messaging, { vapidKey: vapid})
    .then((currentToken) => {
      if (currentToken) {
        console.log('')
        axios ({
          method:'post',
          data:{token:currentToken},
          url:`${process.env.REACT_APP_API_URL_BACK}/submit/token/fcm`,
          withCredentials:true
        }).then((res)=>{
           console.log(``)
        })
        .catch((err)=>{
          console.log('')
        })
      } else {
        console.log(
         // "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
     // console.log("An error occurred while retrieving token. ", err);
    });
  }
  useEffect(()=>{
    if(!go){
      axios({
        method:'get',
        withCredentials:true,
        url:`${process.env.REACT_APP_API_URL_BACK}/get/firebase/auth/${process.env.REACT_APP_API_KEY}`
      })
      .then((res)=>{
        setConfig(res.data[0].config)
        setVapid(res.data[1].vapidKey)

        if(config){
          Allow()
          expConfig = config
          setGo(true)
        }
      })
      .catch((err)=>{
        console.log('')
      })
    }
  })

  return (
    <>
        <Main/>
        <Chat/>
    </>
  );
}
export const ExpConfigFunc = ()=>{
  if(expConfig){
    return expConfig
  }
}
export default App;
